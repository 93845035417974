

















































































































































































import { defineComponent, onMounted, computed, ref, watch } from '@nuxtjs/composition-api';
import { purify } from '~/directives/purify-html';
import { useRouter } from '~/features/router';
import { useSearchProduct } from '~/features/searchProduct';

const gradientClasses = ['gradient-primary-800', 'gradient-primary-700', 'gradient-primary-600'];

/**
 * A Feature Flag to determine to trigger the search upon hovering or not
 */
const IS_PASSIVE = false;

export default defineComponent({
  directives: {
    purify,
  },
  setup() {
    const { route } = useRouter();
    const touched = ref(false);
    const isFocusing = ref(false);

    const {
      searchBy: search,
      result: productsResult,
      categoriesResult,
      execute,
      isFetching,
      fetchBrands,
      brands,
      isFetchingBrands,
    } = useSearchProduct(route.value?.query?.q?.toString() || '', {
      passive: true,
    });
    const show = computed<Boolean>(() => {
      if (IS_PASSIVE && isFocusing.value) {
        execute();
        fetchBrands();
        return true;
      }

      if (search.value && touched.value) {
        return true;
      }

      return !!(
        isFocusing.value &&
        (productsResult.value?.length || categoriesResult.value?.length || isFetching.value)
      );
    });

    onMounted(() => {
      document.addEventListener('click', e => {
        if (e.target instanceof Element && document.getElementById('search-bar')?.contains(e.target)) return;
        isFocusing.value = false;
      });
    });

    // clear search to force close menu when navigating
    watch(route, () => {
      search.value = '';
    });

    const noResults = computed(
      () => !productsResult.value?.length && !categoriesResult.value?.length && !isFetching.value
    );

    function forceTriggerInputBlur() {
      isFocusing.value = false;
    }

    /**
     * Highlights the part of the result string that does not match the search query.
     *
     * @param {String} result - The result from search.
     * @returns {String} - the matched part + The non-matching part wrapped in a `<span>` with the class `font-semibold`.
     *                     If the non-matching part is at the start or no match is found, the original string is returned.
     *
     * @example
     * // Assuming search.value is "test"
     * searchHighlight("test fashion");
     * // Returns 'test<span class="font-semibold"> fashion</span>'
     *
     */
    function searchHighlight(result: String) {
      const searchRegex = new RegExp(search.value, 'i');
      const partToHighlight = result.replace(searchRegex, '');
      const index = result.search(partToHighlight);

      if (index <= 0) return result;

      return result.substring(0, index) + `<span class="font-semibold">${partToHighlight}</span>`;
    }

    return {
      search,
      show,
      productsResult,
      execute,
      isFetching,
      categoriesResult,
      isFocusing,
      noResults,
      forceTriggerInputBlur,
      gradientClasses,
      touched,
      brands,
      isFetchingBrands,
      searchHighlight,
    };
  },
});
