var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"relative flex items-center border-primary-700",class:{
    'w-75': !_vm.show,
    'w-100': _vm.show,
  },attrs:{"id":"search-bar"}},[_c('form',{staticClass:"w-full",attrs:{"method":"get","action":"/search"}},[_c('TextInput',{staticClass:"outline-none text-sm h-11 text-primary-800",attrs:{"id":"search","value":_vm.search,"type":"text","name":"q","autocomplete":"off","role":"combobox","placeholder":_vm.$t('searchProducts'),"aria-label":_vm.$t('searchProducts'),"icon":'search',"filled":true,"rounded":true,"dark":true,"underline":true,"full-border":_vm.show,"is-optional":false,"light":""},on:{"focus":function () { return (_vm.isFocusing = true); },"input":function (e) {
          _vm.search = e;

          _vm.touched = true;
        }},scopedSlots:_vm._u([{key:"after",fn:function(){return [(_vm.show)?_c('button',{staticClass:"[ absolute z-10 ] [ flex items-center justify-center ]",class:{
            'h-6 w-6 right-3 ': _vm.show,
            'underline p-1 rounded-full right-5': !_vm.show,
          },attrs:{"type":"button","aria-label":"clear search words "},on:{"click":function () {
              _vm.search = '';
              _vm.forceTriggerInputBlur();
            }}},[_c('svg-icon',{staticClass:"text-white",class:{
              'text-primary-700': _vm.show,
              'text-white': !_vm.show,
            },attrs:{"name":"close","width":"22","height":"22"}})],1):_vm._e()]},proxy:true}])})],1),_vm._v(" "),_c('transition',{attrs:{"name":"fade"}},[(_vm.show)?_c('div',{staticClass:"searchResult__container shadow-custom absolute bg-white rounded-md w-100 left-0 top-full z-10 [ px-6 ] overflow-y-auto",attrs:{"data-attr":"searchResult__container"}},[_c('div',{staticClass:"text-lg pb-5"},[(_vm.isFetching)?_c('Spinner',{staticClass:"text-primary-700 w-6 h-6 mx-auto"}):(_vm.noResults)?_c('p',{staticClass:"py-2 text-center text-primary-700"},[_vm._v("\n          "+_vm._s(_vm.$t('noResults'))+"\n        ")]):_c('div',{staticClass:"py-4"},[(_vm.categoriesResult && _vm.categoriesResult.length)?_c('div',[_c('h2',{staticClass:"text-black text-left font-body text-lg font-bold"},[_vm._v("\n              "+_vm._s(_vm.$t('suggestedCategories'))+"\n            ")]),_vm._v(" "),(_vm.categoriesResult && _vm.categoriesResult.length)?_c('ul',{staticClass:"my-3 flex flex-wrap gap-4 text-black font-body font-medium text-base",attrs:{"data-attr":"searchResult__list"}},_vm._l((_vm.categoriesResult),function(category,index){return _c('li',{key:index,staticClass:"flex flex-col text-center"},[_c('AppLink',{staticClass:"w-full flex flex-col items-center justify-between rounded-6sm px-3 py-2 uppercase",class:_vm.gradientClasses[
                      ((index % _vm.gradientClasses.length) + _vm.gradientClasses.length) % _vm.gradientClasses.length
                    ],attrs:{"to":("/" + (category.url_path))}},[_c('span',{staticClass:"flex-1 flex flex-col"},[_c('p',{staticClass:"font-normal font-body uppercase",attrs:{"data-attr":"searchResult__item__name"}},[_vm._v("\n                      "+_vm._s(category.name)+"\n                    ")])])])],1)}),0):_vm._e()]):_vm._e(),_vm._v(" "),(_vm.brands && _vm.brands.length)?_c('div',[_c('h2',{staticClass:"text-black text-left font-body text-lg font-bold"},[_vm._v("\n              "+_vm._s(_vm.$t('suggestedBrands'))+"\n            ")]),_vm._v(" "),(_vm.brands && _vm.brands.length)?_c('ul',{staticClass:"my-3 flex flex-wrap gap-4 text-black font-body font-medium text-base",attrs:{"data-attr":"searchResult__list"}},_vm._l((_vm.brands),function(brand,index){return _c('li',{key:index,staticClass:"flex flex-col text-center"},[_c('AppLink',{staticClass:"w-full flex flex-col items-center justify-between rounded-md px-3 py-2 uppercase",class:_vm.gradientClasses[
                      ((index % _vm.gradientClasses.length) + _vm.gradientClasses.length) % _vm.gradientClasses.length
                    ],attrs:{"to":("/brands/" + (brand.url_key))}},[_c('span',{staticClass:"flex-1 flex flex-col"},[_c('p',{staticClass:"font-normal font-body uppercase",attrs:{"data-attr":"searchResult__item__name"}},[_vm._v("\n                      "+_vm._s(brand.name)+"\n                    ")])])])],1)}),0):_vm._e()]):_vm._e(),_vm._v(" "),(_vm.productsResult && _vm.productsResult.length)?_c('div',[_c('h2',{staticClass:"text-black text-left font-body text-lg font-bold"},[_vm._v("\n              "+_vm._s(_vm.$t('suggestedProducts', { count: _vm.productsResult.length }))+"\n            ")]),_vm._v(" "),(_vm.productsResult && _vm.productsResult.length)?_c('ul',{staticClass:"search-result mt-4 text-primary-700",attrs:{"data-attr":"searchResult__list"}},_vm._l((_vm.productsResult),function(item,index){return _c('li',{key:index},[_c('AppLink',{staticClass:"flex w-full",attrs:{"to":("/" + (item.slug))}},[(item.thumb)?_c('img',{staticClass:"w-16 rounded-md h-16 object-contain",attrs:{"data-attr":"searchResult__item__thumb","src":item.thumb.src,"alt":item.thumb.alt}}):_vm._e(),_vm._v(" "),_c('span',{staticClass:"flex-1 flex flex-col"},[_c('p',{staticClass:"text-black flex",attrs:{"data-attr":"searchResult__item__name text-sm"}},[_c('span',{directives:[{name:"purify",rawName:"v-purify",value:(_vm.searchHighlight(item.name)),expression:"searchHighlight(item.name)"}],staticClass:"whitespace-pre"})])])])],1)}),0):_vm._e()]):_vm._e()])],1)]):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }