export const AccountDialog = () => import('../../components/AccountDialog.vue' /* webpackChunkName: "components/account-dialog" */).then(c => wrapFunctional(c.default || c))
export const AccountNavLink = () => import('../../components/AccountNavLink.vue' /* webpackChunkName: "components/account-nav-link" */).then(c => wrapFunctional(c.default || c))
export const AddToCartAlert = () => import('../../components/AddToCartAlert.vue' /* webpackChunkName: "components/add-to-cart-alert" */).then(c => wrapFunctional(c.default || c))
export const Address = () => import('../../components/Address.vue' /* webpackChunkName: "components/address" */).then(c => wrapFunctional(c.default || c))
export const AddressCard = () => import('../../components/AddressCard.vue' /* webpackChunkName: "components/address-card" */).then(c => wrapFunctional(c.default || c))
export const AddressFields = () => import('../../components/AddressFields.vue' /* webpackChunkName: "components/address-fields" */).then(c => wrapFunctional(c.default || c))
export const Alert = () => import('../../components/Alert.vue' /* webpackChunkName: "components/alert" */).then(c => wrapFunctional(c.default || c))
export const AlertGroup = () => import('../../components/AlertGroup.vue' /* webpackChunkName: "components/alert-group" */).then(c => wrapFunctional(c.default || c))
export const AppAccordion = () => import('../../components/AppAccordion.vue' /* webpackChunkName: "components/app-accordion" */).then(c => wrapFunctional(c.default || c))
export const AppButton = () => import('../../components/AppButton.vue' /* webpackChunkName: "components/app-button" */).then(c => wrapFunctional(c.default || c))
export const AppImage = () => import('../../components/AppImage.vue' /* webpackChunkName: "components/app-image" */).then(c => wrapFunctional(c.default || c))
export const AppLink = () => import('../../components/AppLink.js' /* webpackChunkName: "components/app-link" */).then(c => wrapFunctional(c.default || c))
export const AppSource = () => import('../../components/AppSource.vue' /* webpackChunkName: "components/app-source" */).then(c => wrapFunctional(c.default || c))
export const AppStickyBar = () => import('../../components/AppStickyBar.vue' /* webpackChunkName: "components/app-sticky-bar" */).then(c => wrapFunctional(c.default || c))
export const AsideCart = () => import('../../components/AsideCart.vue' /* webpackChunkName: "components/aside-cart" */).then(c => wrapFunctional(c.default || c))
export const AuthSeperator = () => import('../../components/AuthSeperator.vue' /* webpackChunkName: "components/auth-seperator" */).then(c => wrapFunctional(c.default || c))
export const Back = () => import('../../components/Back.vue' /* webpackChunkName: "components/back" */).then(c => wrapFunctional(c.default || c))
export const BrandOffersSlider = () => import('../../components/BrandOffersSlider.vue' /* webpackChunkName: "components/brand-offers-slider" */).then(c => wrapFunctional(c.default || c))
export const BrandSlider = () => import('../../components/BrandSlider.vue' /* webpackChunkName: "components/brand-slider" */).then(c => wrapFunctional(c.default || c))
export const Breadcrumb = () => import('../../components/Breadcrumb.vue' /* webpackChunkName: "components/breadcrumb" */).then(c => wrapFunctional(c.default || c))
export const Bullet = () => import('../../components/Bullet.vue' /* webpackChunkName: "components/bullet" */).then(c => wrapFunctional(c.default || c))
export const BulletsSlider = () => import('../../components/BulletsSlider.vue' /* webpackChunkName: "components/bullets-slider" */).then(c => wrapFunctional(c.default || c))
export const CartItem = () => import('../../components/CartItem.vue' /* webpackChunkName: "components/cart-item" */).then(c => wrapFunctional(c.default || c))
export const CartSummary = () => import('../../components/CartSummary.vue' /* webpackChunkName: "components/cart-summary" */).then(c => wrapFunctional(c.default || c))
export const CategoryBreadcrumb = () => import('../../components/CategoryBreadcrumb.vue' /* webpackChunkName: "components/category-breadcrumb" */).then(c => wrapFunctional(c.default || c))
export const CategoryBullet = () => import('../../components/CategoryBullet.vue' /* webpackChunkName: "components/category-bullet" */).then(c => wrapFunctional(c.default || c))
export const CategoryDialog = () => import('../../components/CategoryDialog.vue' /* webpackChunkName: "components/category-dialog" */).then(c => wrapFunctional(c.default || c))
export const CategoryFlatSlider = () => import('../../components/CategoryFlatSlider.vue' /* webpackChunkName: "components/category-flat-slider" */).then(c => wrapFunctional(c.default || c))
export const CategoryMegaMenu = () => import('../../components/CategoryMegaMenu.vue' /* webpackChunkName: "components/category-mega-menu" */).then(c => wrapFunctional(c.default || c))
export const CategoryOffersSlider = () => import('../../components/CategoryOffersSlider.vue' /* webpackChunkName: "components/category-offers-slider" */).then(c => wrapFunctional(c.default || c))
export const CategorySlider = () => import('../../components/CategorySlider.vue' /* webpackChunkName: "components/category-slider" */).then(c => wrapFunctional(c.default || c))
export const CategoryTree = () => import('../../components/CategoryTree.vue' /* webpackChunkName: "components/category-tree" */).then(c => wrapFunctional(c.default || c))
export const CheckboxInput = () => import('../../components/CheckboxInput.vue' /* webpackChunkName: "components/checkbox-input" */).then(c => wrapFunctional(c.default || c))
export const CheckoutSteps = () => import('../../components/CheckoutSteps.vue' /* webpackChunkName: "components/checkout-steps" */).then(c => wrapFunctional(c.default || c))
export const ComparisonMenu = () => import('../../components/ComparisonMenu.vue' /* webpackChunkName: "components/comparison-menu" */).then(c => wrapFunctional(c.default || c))
export const ContactMap = () => import('../../components/ContactMap.vue' /* webpackChunkName: "components/contact-map" */).then(c => wrapFunctional(c.default || c))
export const Coupons = () => import('../../components/Coupons.vue' /* webpackChunkName: "components/coupons" */).then(c => wrapFunctional(c.default || c))
export const DownloadAppBanner = () => import('../../components/DownloadAppBanner.vue' /* webpackChunkName: "components/download-app-banner" */).then(c => wrapFunctional(c.default || c))
export const EVoucherCard = () => import('../../components/EVoucherCard.vue' /* webpackChunkName: "components/e-voucher-card" */).then(c => wrapFunctional(c.default || c))
export const EmptyMessage = () => import('../../components/EmptyMessage.vue' /* webpackChunkName: "components/empty-message" */).then(c => wrapFunctional(c.default || c))
export const ExpandTransition = () => import('../../components/ExpandTransition.vue' /* webpackChunkName: "components/expand-transition" */).then(c => wrapFunctional(c.default || c))
export const FacetDialog = () => import('../../components/FacetDialog.vue' /* webpackChunkName: "components/facet-dialog" */).then(c => wrapFunctional(c.default || c))
export const FacetDropdown = () => import('../../components/FacetDropdown.vue' /* webpackChunkName: "components/facet-dropdown" */).then(c => wrapFunctional(c.default || c))
export const FacetsTagsList = () => import('../../components/FacetsTagsList.vue' /* webpackChunkName: "components/facets-tags-list" */).then(c => wrapFunctional(c.default || c))
export const FavoriteButton = () => import('../../components/FavoriteButton.vue' /* webpackChunkName: "components/favorite-button" */).then(c => wrapFunctional(c.default || c))
export const FileInput = () => import('../../components/FileInput.vue' /* webpackChunkName: "components/file-input" */).then(c => wrapFunctional(c.default || c))
export const GenericMultiselect = () => import('../../components/GenericMultiselect.vue' /* webpackChunkName: "components/generic-multiselect" */).then(c => wrapFunctional(c.default || c))
export const GiftCard = () => import('../../components/GiftCard.vue' /* webpackChunkName: "components/gift-card" */).then(c => wrapFunctional(c.default || c))
export const GiftCardAmountSelector = () => import('../../components/GiftCardAmountSelector.vue' /* webpackChunkName: "components/gift-card-amount-selector" */).then(c => wrapFunctional(c.default || c))
export const GiftCardForm = () => import('../../components/GiftCardForm.vue' /* webpackChunkName: "components/gift-card-form" */).then(c => wrapFunctional(c.default || c))
export const GiftWrapping = () => import('../../components/GiftWrapping.vue' /* webpackChunkName: "components/gift-wrapping" */).then(c => wrapFunctional(c.default || c))
export const HappyHour = () => import('../../components/HappyHour.vue' /* webpackChunkName: "components/happy-hour" */).then(c => wrapFunctional(c.default || c))
export const InfiniteScrollObserver = () => import('../../components/InfiniteScrollObserver.vue' /* webpackChunkName: "components/infinite-scroll-observer" */).then(c => wrapFunctional(c.default || c))
export const LazyImage = () => import('../../components/LazyImage.vue' /* webpackChunkName: "components/lazy-image" */).then(c => wrapFunctional(c.default || c))
export const LazySource = () => import('../../components/LazySource.vue' /* webpackChunkName: "components/lazy-source" */).then(c => wrapFunctional(c.default || c))
export const LoginModal = () => import('../../components/LoginModal.vue' /* webpackChunkName: "components/login-modal" */).then(c => wrapFunctional(c.default || c))
export const LoyaltyPoints = () => import('../../components/LoyaltyPoints.vue' /* webpackChunkName: "components/loyalty-points" */).then(c => wrapFunctional(c.default || c))
export const MemoryGameCards = () => import('../../components/MemoryGameCards.vue' /* webpackChunkName: "components/memory-game-cards" */).then(c => wrapFunctional(c.default || c))
export const ModalDialog = () => import('../../components/ModalDialog.vue' /* webpackChunkName: "components/modal-dialog" */).then(c => wrapFunctional(c.default || c))
export const Money = () => import('../../components/Money.ts' /* webpackChunkName: "components/money" */).then(c => wrapFunctional(c.default || c))
export const OfferCard = () => import('../../components/OfferCard.vue' /* webpackChunkName: "components/offer-card" */).then(c => wrapFunctional(c.default || c))
export const OfferSlider = () => import('../../components/OfferSlider.vue' /* webpackChunkName: "components/offer-slider" */).then(c => wrapFunctional(c.default || c))
export const OrderCard = () => import('../../components/OrderCard.vue' /* webpackChunkName: "components/order-card" */).then(c => wrapFunctional(c.default || c))
export const OrderProgress = () => import('../../components/OrderProgress.vue' /* webpackChunkName: "components/order-progress" */).then(c => wrapFunctional(c.default || c))
export const PageInfo = () => import('../../components/PageInfo.vue' /* webpackChunkName: "components/page-info" */).then(c => wrapFunctional(c.default || c))
export const PaymentSummary = () => import('../../components/PaymentSummary.vue' /* webpackChunkName: "components/payment-summary" */).then(c => wrapFunctional(c.default || c))
export const PriceFacet = () => import('../../components/PriceFacet.vue' /* webpackChunkName: "components/price-facet" */).then(c => wrapFunctional(c.default || c))
export const ProductBundleCustomizer = () => import('../../components/ProductBundleCustomizer.vue' /* webpackChunkName: "components/product-bundle-customizer" */).then(c => wrapFunctional(c.default || c))
export const ProductCard = () => import('../../components/ProductCard.vue' /* webpackChunkName: "components/product-card" */).then(c => wrapFunctional(c.default || c))
export const ProductCompareCard = () => import('../../components/ProductCompareCard.vue' /* webpackChunkName: "components/product-compare-card" */).then(c => wrapFunctional(c.default || c))
export const ProductGroupCustomizer = () => import('../../components/ProductGroupCustomizer.vue' /* webpackChunkName: "components/product-group-customizer" */).then(c => wrapFunctional(c.default || c))
export const ProductImageGallerySlider = () => import('../../components/ProductImageGallerySlider.vue' /* webpackChunkName: "components/product-image-gallery-slider" */).then(c => wrapFunctional(c.default || c))
export const ProductPageBreadcrumb = () => import('../../components/ProductPageBreadcrumb.vue' /* webpackChunkName: "components/product-page-breadcrumb" */).then(c => wrapFunctional(c.default || c))
export const ProductPageDetails = () => import('../../components/ProductPageDetails.vue' /* webpackChunkName: "components/product-page-details" */).then(c => wrapFunctional(c.default || c))
export const ProductPageGallery = () => import('../../components/ProductPageGallery.vue' /* webpackChunkName: "components/product-page-gallery" */).then(c => wrapFunctional(c.default || c))
export const ProductPageRelated = () => import('../../components/ProductPageRelated.vue' /* webpackChunkName: "components/product-page-related" */).then(c => wrapFunctional(c.default || c))
export const ProductPageRelatedProducts = () => import('../../components/ProductPageRelatedProducts.vue' /* webpackChunkName: "components/product-page-related-products" */).then(c => wrapFunctional(c.default || c))
export const ProductPageReviews = () => import('../../components/ProductPageReviews.vue' /* webpackChunkName: "components/product-page-reviews" */).then(c => wrapFunctional(c.default || c))
export const ProductPageSuggestions = () => import('../../components/ProductPageSuggestions.vue' /* webpackChunkName: "components/product-page-suggestions" */).then(c => wrapFunctional(c.default || c))
export const ProductPageViewSlider = () => import('../../components/ProductPageViewSlider.vue' /* webpackChunkName: "components/product-page-view-slider" */).then(c => wrapFunctional(c.default || c))
export const ProductRating = () => import('../../components/ProductRating.vue' /* webpackChunkName: "components/product-rating" */).then(c => wrapFunctional(c.default || c))
export const ProductRatingStars = () => import('../../components/ProductRatingStars.vue' /* webpackChunkName: "components/product-rating-stars" */).then(c => wrapFunctional(c.default || c))
export const ProductReview = () => import('../../components/ProductReview.vue' /* webpackChunkName: "components/product-review" */).then(c => wrapFunctional(c.default || c))
export const ProductSlider = () => import('../../components/ProductSlider.vue' /* webpackChunkName: "components/product-slider" */).then(c => wrapFunctional(c.default || c))
export const ProductVariantSelector = () => import('../../components/ProductVariantSelector.vue' /* webpackChunkName: "components/product-variant-selector" */).then(c => wrapFunctional(c.default || c))
export const ProductsCarousel = () => import('../../components/ProductsCarousel.vue' /* webpackChunkName: "components/products-carousel" */).then(c => wrapFunctional(c.default || c))
export const ProductsListing = () => import('../../components/ProductsListing.vue' /* webpackChunkName: "components/products-listing" */).then(c => wrapFunctional(c.default || c))
export const ProfileDropdown = () => import('../../components/ProfileDropdown.vue' /* webpackChunkName: "components/profile-dropdown" */).then(c => wrapFunctional(c.default || c))
export const ProgressBar = () => import('../../components/ProgressBar.vue' /* webpackChunkName: "components/progress-bar" */).then(c => wrapFunctional(c.default || c))
export const QtyControls = () => import('../../components/QtyControls.vue' /* webpackChunkName: "components/qty-controls" */).then(c => wrapFunctional(c.default || c))
export const QuantityInput = () => import('../../components/QuantityInput.vue' /* webpackChunkName: "components/quantity-input" */).then(c => wrapFunctional(c.default || c))
export const QuickSearch = () => import('../../components/QuickSearch.vue' /* webpackChunkName: "components/quick-search" */).then(c => wrapFunctional(c.default || c))
export const RadioInput = () => import('../../components/RadioInput.vue' /* webpackChunkName: "components/radio-input" */).then(c => wrapFunctional(c.default || c))
export const ReviewInput = () => import('../../components/ReviewInput.vue' /* webpackChunkName: "components/review-input" */).then(c => wrapFunctional(c.default || c))
export const ReviewOrderForm = () => import('../../components/ReviewOrderForm.vue' /* webpackChunkName: "components/review-order-form" */).then(c => wrapFunctional(c.default || c))
export const RoundedSticker = () => import('../../components/RoundedSticker.vue' /* webpackChunkName: "components/rounded-sticker" */).then(c => wrapFunctional(c.default || c))
export const RoundedText = () => import('../../components/RoundedText.vue' /* webpackChunkName: "components/rounded-text" */).then(c => wrapFunctional(c.default || c))
export const ScrollDown = () => import('../../components/ScrollDown.vue' /* webpackChunkName: "components/scroll-down" */).then(c => wrapFunctional(c.default || c))
export const ScrollToBottom = () => import('../../components/ScrollToBottom.vue' /* webpackChunkName: "components/scroll-to-bottom" */).then(c => wrapFunctional(c.default || c))
export const SearchDialog = () => import('../../components/SearchDialog.vue' /* webpackChunkName: "components/search-dialog" */).then(c => wrapFunctional(c.default || c))
export const SectionTitle = () => import('../../components/SectionTitle.vue' /* webpackChunkName: "components/section-title" */).then(c => wrapFunctional(c.default || c))
export const SelectInput = () => import('../../components/SelectInput.vue' /* webpackChunkName: "components/select-input" */).then(c => wrapFunctional(c.default || c))
export const SelectedFacets = () => import('../../components/SelectedFacets.vue' /* webpackChunkName: "components/selected-facets" */).then(c => wrapFunctional(c.default || c))
export const ShareButton = () => import('../../components/ShareButton.vue' /* webpackChunkName: "components/share-button" */).then(c => wrapFunctional(c.default || c))
export const ShippingSummary = () => import('../../components/ShippingSummary.vue' /* webpackChunkName: "components/shipping-summary" */).then(c => wrapFunctional(c.default || c))
export const SideMenu = () => import('../../components/SideMenu.vue' /* webpackChunkName: "components/side-menu" */).then(c => wrapFunctional(c.default || c))
export const SocialButtons = () => import('../../components/SocialButtons.vue' /* webpackChunkName: "components/social-buttons" */).then(c => wrapFunctional(c.default || c))
export const SortProductsDropdown = () => import('../../components/SortProductsDropdown.vue' /* webpackChunkName: "components/sort-products-dropdown" */).then(c => wrapFunctional(c.default || c))
export const Spinner = () => import('../../components/Spinner.vue' /* webpackChunkName: "components/spinner" */).then(c => wrapFunctional(c.default || c))
export const StoreLocationSummary = () => import('../../components/StoreLocationSummary.vue' /* webpackChunkName: "components/store-location-summary" */).then(c => wrapFunctional(c.default || c))
export const TextInput = () => import('../../components/TextInput.vue' /* webpackChunkName: "components/text-input" */).then(c => wrapFunctional(c.default || c))
export const TheAlerts = () => import('../../components/TheAlerts.vue' /* webpackChunkName: "components/the-alerts" */).then(c => wrapFunctional(c.default || c))
export const TheFooter = () => import('../../components/TheFooter.vue' /* webpackChunkName: "components/the-footer" */).then(c => wrapFunctional(c.default || c))
export const TheHeader = () => import('../../components/TheHeader.vue' /* webpackChunkName: "components/the-header" */).then(c => wrapFunctional(c.default || c))
export const Timer = () => import('../../components/Timer.vue' /* webpackChunkName: "components/timer" */).then(c => wrapFunctional(c.default || c))
export const ToggleInput = () => import('../../components/ToggleInput.vue' /* webpackChunkName: "components/toggle-input" */).then(c => wrapFunctional(c.default || c))
export const VariantModalDialog = () => import('../../components/VariantModalDialog.vue' /* webpackChunkName: "components/variant-modal-dialog" */).then(c => wrapFunctional(c.default || c))
export const VerifyOtp = () => import('../../components/VerifyOtp.vue' /* webpackChunkName: "components/verify-otp" */).then(c => wrapFunctional(c.default || c))
export const CarouselBestSellers = () => import('../../components/Carousel/BestSellers.vue' /* webpackChunkName: "components/carousel-best-sellers" */).then(c => wrapFunctional(c.default || c))
export const CarouselLatestProducts = () => import('../../components/Carousel/LatestProducts.vue' /* webpackChunkName: "components/carousel-latest-products" */).then(c => wrapFunctional(c.default || c))
export const CarouselWrapper = () => import('../../components/Carousel/Wrapper.vue' /* webpackChunkName: "components/carousel-wrapper" */).then(c => wrapFunctional(c.default || c))
export const CartGiftTiers = () => import('../../components/Cart/GiftTiers.vue' /* webpackChunkName: "components/cart-gift-tiers" */).then(c => wrapFunctional(c.default || c))
export const CartMemoryGame = () => import('../../components/Cart/MemoryGame.vue' /* webpackChunkName: "components/cart-memory-game" */).then(c => wrapFunctional(c.default || c))
export const CartPaymentSummary = () => import('../../components/Cart/PaymentSummary.vue' /* webpackChunkName: "components/cart-payment-summary" */).then(c => wrapFunctional(c.default || c))
export const CartPriceRuleTiers = () => import('../../components/Cart/PriceRuleTiers.vue' /* webpackChunkName: "components/cart-price-rule-tiers" */).then(c => wrapFunctional(c.default || c))
export const CartSpinningWheel = () => import('../../components/Cart/SpinningWheel.vue' /* webpackChunkName: "components/cart-spinning-wheel" */).then(c => wrapFunctional(c.default || c))
export const CartWheelGift = () => import('../../components/Cart/WheelGift.vue' /* webpackChunkName: "components/cart-wheel-gift" */).then(c => wrapFunctional(c.default || c))
export const CartItemGiftWrapping = () => import('../../components/CartItem/GiftWrapping.vue' /* webpackChunkName: "components/cart-item-gift-wrapping" */).then(c => wrapFunctional(c.default || c))
export const CartItemQtyControl = () => import('../../components/CartItem/QtyControl.vue' /* webpackChunkName: "components/cart-item-qty-control" */).then(c => wrapFunctional(c.default || c))
export const CheckoutAddressPicker = () => import('../../components/Checkout/AddressPicker.vue' /* webpackChunkName: "components/checkout-address-picker" */).then(c => wrapFunctional(c.default || c))
export const CheckoutCartSummary = () => import('../../components/Checkout/CartSummary.vue' /* webpackChunkName: "components/checkout-cart-summary" */).then(c => wrapFunctional(c.default || c))
export const CheckoutCoupons = () => import('../../components/Checkout/Coupons.vue' /* webpackChunkName: "components/checkout-coupons" */).then(c => wrapFunctional(c.default || c))
export const CheckoutDeliveryTypePicker = () => import('../../components/Checkout/DeliveryTypePicker.vue' /* webpackChunkName: "components/checkout-delivery-type-picker" */).then(c => wrapFunctional(c.default || c))
export const CheckoutGiftCard = () => import('../../components/Checkout/GiftCard.vue' /* webpackChunkName: "components/checkout-gift-card" */).then(c => wrapFunctional(c.default || c))
export const CheckoutLoyaltyPoints = () => import('../../components/Checkout/LoyaltyPoints.vue' /* webpackChunkName: "components/checkout-loyalty-points" */).then(c => wrapFunctional(c.default || c))
export const CheckoutOffersDropdown = () => import('../../components/Checkout/OffersDropdown.vue' /* webpackChunkName: "components/checkout-offers-dropdown" */).then(c => wrapFunctional(c.default || c))
export const CheckoutPaymentSummary = () => import('../../components/Checkout/PaymentSummary.vue' /* webpackChunkName: "components/checkout-payment-summary" */).then(c => wrapFunctional(c.default || c))
export const CheckoutShippingSummary = () => import('../../components/Checkout/ShippingSummary.vue' /* webpackChunkName: "components/checkout-shipping-summary" */).then(c => wrapFunctional(c.default || c))
export const CheckoutShippingTypePicker = () => import('../../components/Checkout/ShippingTypePicker.vue' /* webpackChunkName: "components/checkout-shipping-type-picker" */).then(c => wrapFunctional(c.default || c))
export const CheckoutStorePicker = () => import('../../components/Checkout/StorePicker.vue' /* webpackChunkName: "components/checkout-store-picker" */).then(c => wrapFunctional(c.default || c))
export const CompareMenuCard = () => import('../../components/Compare/MenuCard.vue' /* webpackChunkName: "components/compare-menu-card" */).then(c => wrapFunctional(c.default || c))
export const CompareMenuItemSlider = () => import('../../components/Compare/MenuItemSlider.vue' /* webpackChunkName: "components/compare-menu-item-slider" */).then(c => wrapFunctional(c.default || c))
export const CompareMinimalProductCard = () => import('../../components/Compare/MinimalProductCard.vue' /* webpackChunkName: "components/compare-minimal-product-card" */).then(c => wrapFunctional(c.default || c))
export const FacetTagPrice = () => import('../../components/FacetTag/Price.vue' /* webpackChunkName: "components/facet-tag-price" */).then(c => wrapFunctional(c.default || c))
export const FacetTagSelect = () => import('../../components/FacetTag/Select.vue' /* webpackChunkName: "components/facet-tag-select" */).then(c => wrapFunctional(c.default || c))
export const FaqsFilters = () => import('../../components/Faqs/FaqsFilters.vue' /* webpackChunkName: "components/faqs-filters" */).then(c => wrapFunctional(c.default || c))
export const FaqsList = () => import('../../components/Faqs/FaqsList.vue' /* webpackChunkName: "components/faqs-list" */).then(c => wrapFunctional(c.default || c))
export const FaqsListItem = () => import('../../components/Faqs/FaqsListItem.vue' /* webpackChunkName: "components/faqs-list-item" */).then(c => wrapFunctional(c.default || c))
export const HomeCategoryFragment = () => import('../../components/Home/CategoryFragment.vue' /* webpackChunkName: "components/home-category-fragment" */).then(c => wrapFunctional(c.default || c))
export const HomeComponentFactory = () => import('../../components/Home/ComponentFactory.vue' /* webpackChunkName: "components/home-component-factory" */).then(c => wrapFunctional(c.default || c))
export const HomeFeaturedBrandsFragment = () => import('../../components/Home/FeaturedBrandsFragment.vue' /* webpackChunkName: "components/home-featured-brands-fragment" */).then(c => wrapFunctional(c.default || c))
export const HomeInformativeBannerFragment = () => import('../../components/Home/InformativeBannerFragment.vue' /* webpackChunkName: "components/home-informative-banner-fragment" */).then(c => wrapFunctional(c.default || c))
export const HomeNonCachedProductsFragment = () => import('../../components/Home/NonCachedProductsFragment.vue' /* webpackChunkName: "components/home-non-cached-products-fragment" */).then(c => wrapFunctional(c.default || c))
export const HomeOfferComponentFactory = () => import('../../components/Home/OfferComponentFactory.vue' /* webpackChunkName: "components/home-offer-component-factory" */).then(c => wrapFunctional(c.default || c))
export const HomeOffersBlockingSliderFragment = () => import('../../components/Home/OffersBlockingSliderFragment.vue' /* webpackChunkName: "components/home-offers-blocking-slider-fragment" */).then(c => wrapFunctional(c.default || c))
export const HomeOffersFragment = () => import('../../components/Home/OffersFragment.vue' /* webpackChunkName: "components/home-offers-fragment" */).then(c => wrapFunctional(c.default || c))
export const HomeOffersModelArt1 = () => import('../../components/Home/OffersModelArt1.vue' /* webpackChunkName: "components/home-offers-model-art1" */).then(c => wrapFunctional(c.default || c))
export const HomeOffersModelArt2 = () => import('../../components/Home/OffersModelArt2.vue' /* webpackChunkName: "components/home-offers-model-art2" */).then(c => wrapFunctional(c.default || c))
export const HomeOffersSliderFragment = () => import('../../components/Home/OffersSliderFragment.vue' /* webpackChunkName: "components/home-offers-slider-fragment" */).then(c => wrapFunctional(c.default || c))
export const HomeOffersVerticalFragment = () => import('../../components/Home/OffersVerticalFragment.vue' /* webpackChunkName: "components/home-offers-vertical-fragment" */).then(c => wrapFunctional(c.default || c))
export const HomeProductCarouselFragment = () => import('../../components/Home/ProductCarouselFragment.vue' /* webpackChunkName: "components/home-product-carousel-fragment" */).then(c => wrapFunctional(c.default || c))
export const LoadingBlogListing = () => import('../../components/Loading/BlogListing.vue' /* webpackChunkName: "components/loading-blog-listing" */).then(c => wrapFunctional(c.default || c))
export const LoadingCategorySlider = () => import('../../components/Loading/CategorySlider.vue' /* webpackChunkName: "components/loading-category-slider" */).then(c => wrapFunctional(c.default || c))
export const LoadingDots = () => import('../../components/Loading/Dots.vue' /* webpackChunkName: "components/loading-dots" */).then(c => wrapFunctional(c.default || c))
export const LoadingHistoryProductListing = () => import('../../components/Loading/HistoryProductListing.vue' /* webpackChunkName: "components/loading-history-product-listing" */).then(c => wrapFunctional(c.default || c))
export const LoadingMainTitle = () => import('../../components/Loading/LoadingMainTitle.vue' /* webpackChunkName: "components/loading-main-title" */).then(c => wrapFunctional(c.default || c))
export const LoadingOfferSlider = () => import('../../components/Loading/OfferSlider.vue' /* webpackChunkName: "components/loading-offer-slider" */).then(c => wrapFunctional(c.default || c))
export const LoadingProductCard = () => import('../../components/Loading/ProductCard.vue' /* webpackChunkName: "components/loading-product-card" */).then(c => wrapFunctional(c.default || c))
export const LoadingProductImageGallerySlider = () => import('../../components/Loading/ProductImageGallerySlider.vue' /* webpackChunkName: "components/loading-product-image-gallery-slider" */).then(c => wrapFunctional(c.default || c))
export const LoadingProductPage = () => import('../../components/Loading/ProductPage.vue' /* webpackChunkName: "components/loading-product-page" */).then(c => wrapFunctional(c.default || c))
export const LoadingProductPageDetails = () => import('../../components/Loading/ProductPageDetails.vue' /* webpackChunkName: "components/loading-product-page-details" */).then(c => wrapFunctional(c.default || c))
export const LoadingProductPageViewSlider = () => import('../../components/Loading/ProductPageViewSlider.vue' /* webpackChunkName: "components/loading-product-page-view-slider" */).then(c => wrapFunctional(c.default || c))
export const LoadingProductSlider = () => import('../../components/Loading/ProductSlider.vue' /* webpackChunkName: "components/loading-product-slider" */).then(c => wrapFunctional(c.default || c))
export const LoadingProductsList = () => import('../../components/Loading/ProductsList.vue' /* webpackChunkName: "components/loading-products-list" */).then(c => wrapFunctional(c.default || c))
export const LoadingProductsListPage = () => import('../../components/Loading/ProductsListPage.vue' /* webpackChunkName: "components/loading-products-list-page" */).then(c => wrapFunctional(c.default || c))
export const LoadingSearchDialog = () => import('../../components/Loading/SearchDialog.vue' /* webpackChunkName: "components/loading-search-dialog" */).then(c => wrapFunctional(c.default || c))
export const ModalMemoryGame = () => import('../../components/Modal/MemoryGame.vue' /* webpackChunkName: "components/modal-memory-game" */).then(c => wrapFunctional(c.default || c))
export const ModalSpinTheWheel = () => import('../../components/Modal/SpinTheWheel.vue' /* webpackChunkName: "components/modal-spin-the-wheel" */).then(c => wrapFunctional(c.default || c))
export const ModalVerifyOtp = () => import('../../components/Modal/VerifyOtp.vue' /* webpackChunkName: "components/modal-verify-otp" */).then(c => wrapFunctional(c.default || c))
export const OfferBlockingSlider = () => import('../../components/Offer/BlockingSlider.vue' /* webpackChunkName: "components/offer-blocking-slider" */).then(c => wrapFunctional(c.default || c))
export const OfferDoubleModelArtCard = () => import('../../components/Offer/DoubleModelArtCard.vue' /* webpackChunkName: "components/offer-double-model-art-card" */).then(c => wrapFunctional(c.default || c))
export const OfferModelArtCard = () => import('../../components/Offer/ModelArtCard.vue' /* webpackChunkName: "components/offer-model-art-card" */).then(c => wrapFunctional(c.default || c))
export const OrderReviewModal = () => import('../../components/Order/reviewModal.vue' /* webpackChunkName: "components/order-review-modal" */).then(c => wrapFunctional(c.default || c))
export const PaymentStrategy = () => import('../../components/Payment/Strategy.vue' /* webpackChunkName: "components/payment-strategy" */).then(c => wrapFunctional(c.default || c))
export const PaymentStrategyDirector = () => import('../../components/Payment/StrategyDirector.vue' /* webpackChunkName: "components/payment-strategy-director" */).then(c => wrapFunctional(c.default || c))
export const ProductAttributesSlideShow = () => import('../../components/Product/AttributesSlideShow.vue' /* webpackChunkName: "components/product-attributes-slide-show" */).then(c => wrapFunctional(c.default || c))
export const ProductCartControls = () => import('../../components/Product/CartControls.vue' /* webpackChunkName: "components/product-cart-controls" */).then(c => wrapFunctional(c.default || c))
export const ProductGiftWrapping = () => import('../../components/Product/GiftWrapping.vue' /* webpackChunkName: "components/product-gift-wrapping" */).then(c => wrapFunctional(c.default || c))
export const ProductPriceSection = () => import('../../components/Product/PriceSection.vue' /* webpackChunkName: "components/product-price-section" */).then(c => wrapFunctional(c.default || c))
export const ProductGift = () => import('../../components/Product/ProductGift.vue' /* webpackChunkName: "components/product-gift" */).then(c => wrapFunctional(c.default || c))
export const ProductStickyBar = () => import('../../components/Product/StickyBar.vue' /* webpackChunkName: "components/product-sticky-bar" */).then(c => wrapFunctional(c.default || c))
export const ProductWrapAsGift = () => import('../../components/Product/WrapAsGift.vue' /* webpackChunkName: "components/product-wrap-as-gift" */).then(c => wrapFunctional(c.default || c))
export const ProductCardOptionsDisplay = () => import('../../components/ProductCard/OptionsDisplay.vue' /* webpackChunkName: "components/product-card-options-display" */).then(c => wrapFunctional(c.default || c))
export const ProductCardQtyControl = () => import('../../components/ProductCard/QtyControl.vue' /* webpackChunkName: "components/product-card-qty-control" */).then(c => wrapFunctional(c.default || c))
export const ProductCardThumb = () => import('../../components/ProductCard/Thumb.vue' /* webpackChunkName: "components/product-card-thumb" */).then(c => wrapFunctional(c.default || c))
export const ProductCardThumbSlider = () => import('../../components/ProductCard/ThumbSlider.vue' /* webpackChunkName: "components/product-card-thumb-slider" */).then(c => wrapFunctional(c.default || c))
export const SpinTheWheelRewardCard = () => import('../../components/SpinTheWheel/RewardCard.vue' /* webpackChunkName: "components/spin-the-wheel-reward-card" */).then(c => wrapFunctional(c.default || c))
export const SpinTheWheelTimer = () => import('../../components/SpinTheWheel/Timer.vue' /* webpackChunkName: "components/spin-the-wheel-timer" */).then(c => wrapFunctional(c.default || c))
export const SpinTheWheel = () => import('../../components/SpinTheWheel/Wheel.vue' /* webpackChunkName: "components/spin-the-wheel" */).then(c => wrapFunctional(c.default || c))
export const TransitionFade = () => import('../../components/Transition/Fade.js' /* webpackChunkName: "components/transition-fade" */).then(c => wrapFunctional(c.default || c))
export const TransitionSlideUp = () => import('../../components/Transition/SlideUp.js' /* webpackChunkName: "components/transition-slide-up" */).then(c => wrapFunctional(c.default || c))
export const ComposeAddToCartAlert = () => import('../../components/compose/AddToCartAlert.vue' /* webpackChunkName: "components/compose-add-to-cart-alert" */).then(c => wrapFunctional(c.default || c))
export const ComposeCarouselViewer = () => import('../../components/compose/CarouselViewer.vue' /* webpackChunkName: "components/compose-carousel-viewer" */).then(c => wrapFunctional(c.default || c))
export const PaymentWithPayfort = () => import('../../components/Payment/With/Payfort.vue' /* webpackChunkName: "components/payment-with-payfort" */).then(c => wrapFunctional(c.default || c))
export const PaymentWithPaymob = () => import('../../components/Payment/With/Paymob.vue' /* webpackChunkName: "components/payment-with-paymob" */).then(c => wrapFunctional(c.default || c))
export const PaymentWithWallet = () => import('../../components/Payment/With/Wallet.vue' /* webpackChunkName: "components/payment-with-wallet" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
